import React from 'react'
import Link from 'gatsby-link'
import MainLayout from '../components/layout'
import styled from '@emotion/styled'

const TagStyles = styled.div`
  background-color: ${props => props.theme.bg.primary};
  color: ${props => props.theme.text.primary};
  height: 100vh;

  padding: 20px;
`

const Tags = ({ pageContext }) => {
  const { posts, tagName } = pageContext

  if (posts) {
    return (
      <MainLayout>
        <TagStyles>
          <span>
            Posts about <strong>{tagName}</strong>:
          </span>

          <ul>
            {posts.map(post => {
              return (
                <li key={post.frontmatter.path}>
                  <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </TagStyles>
      </MainLayout>
    )
  }
}

export default Tags
